import React, { useState, useEffect } from 'react';
import useStore from './store';
import debounce from 'lodash.debounce';
import axios from 'axios';
const App = () => {
	const store = useStore();
	const [periods, setPeriods] = useState([]);
	const [family, isFamily] = useState(null);
	const [selectedPeriod, setSelectedPeriod] = useState(null);
	const [name, setName] = useState(null);
	const [phone, setPhone] = useState(null);
	const [idNumber, setIdNumber] = useState(null);
	const [secondIdNumber, setSecondIdNumber] = useState(null);
	const [sent, setSent] = useState(false);
	const [userId, setUserId] = useState(null);
	const [textPeriod, setTextPeriod] = useState(null);
	const [hasKid, setHasKid] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [isClose, setIsClose] = useState(false);
	const [termsRead, setTermsRead] = useState(false);
	const [terms, setTerms] = useState(null);

	const get_perids = async () => {
		axios.get('https://maeda.com.sa/khaleej/api/terms.php').then((response) => {
			setTerms(response.data.message);
			if (response.data.isClose === '1') {
				setIsClose(true);
			}
		});
		axios.get('https://maeda.com.sa/khaleej/api/periods.php').then((response) => {
			setPeriods(response.data);
		});
	};

	useEffect(() => {
		get_perids();
	}, []);

	const submit = (id) => {
		if (name === null || name.length < 6) {
			alert('يرجى كتابة الاسم بشكل صحيح');
			setSubmitted(false);
			return;
		}
		if (phone === null || phone.length !== 10 || !phone.startsWith('05')) {
			alert('يرجى كتابة رقم الجوال بشكل صحيح');
			setSubmitted(false);
			return;
		}
		if (idNumber === null || idNumber.length !== 10) {
			alert('يرجى كتابة رقم الهوية بشكل صحيح');
			setSubmitted(false);
			return;
		}
		if (selectedPeriod === null) {
			alert('يرجى اختيار وقت استلام التذكرة');
			setSubmitted(false);
			return;
		}
		const time = periods.filter((i) => i.id == id);
		setTextPeriod(time[0].period);
		store.setName(name);
		store.setPhone(phone);
		store.setDate(time[0].date);
		store.setLocation(time[0].location);
		axios
			.get(
				`https://maeda.com.sa/khaleej/api/booking.php?phone=${phone}&idNumber=${idNumber}&name=${name}&booking_period_id=${selectedPeriod}&hasKid=${hasKid
					? 1
					: 0}&isFamily=${family}&second_id_number=${secondIdNumber}`
			)
			.then((response) => {
				if (response.data.status === 200) {
					axios.get(`https://maeda.com.sa/khaleej/api/getInfo.php?phone=${phone}`).then((res) => {
						if (res.data.status === 200) {
							setUserId(res.data.id);
							setSent(true);
						}
					});
				} else if (response.data.status === 407) {
					alert('نفدت التذاكر لهذه الفترة');
					setSubmitted(false);
				} else {
					alert('يوجد لديك حجز مسبق');
					setSubmitted(false);
				}
				console.log('Status', response.data);
			});
	};

	const period_list = () => {
		var familyTrue;
		if (family === 'yes') {
			familyTrue = '1';
		} else {
			familyTrue = '0';
		}
		const filter = periods.filter((i) => i.isFamily === familyTrue);
		return filter.map((item, index) => (
			<div
				className="period"
				key={index}
				onClick={() => [setSelectedPeriod(item.quantity > 0 ? item.id : null)]}
				style={{
					background:
						selectedPeriod !== item.id
							? '#f2f2f2'
							: item.quantity < 1 && selectedPeriod === item.id ? '#f2f2f2' : '#fff',
					opacity: item.quantity < 1 ? 0.5 : 1,
					border: `solid 3px ${selectedPeriod !== item.id
						? '#f2f2f2'
						: item.quantity < 1 && selectedPeriod === item.id ? '#f2f2f2' : 'green'}`
				}}
			>
				<div className="periods">
					<p className="date">{item.date}</p>
					{item.period}

					<p className="location">{item.location}</p>
					{item.quantity > 0 ? (
						<div className="period_quantity">{item.quantity} تذكرة متوفرة</div>
					) : (
						<div className="period_quantity" style={{ color: 'red' }}>
							نفدت التذاكر
						</div>
					)}
				</div>
			</div>
		));
	};
	if (isClose) {
		return (
			<div className="termContainer">
				<img src="https://maeda.com.sa/khaleej/win/khaleej_logo.png" className="logo" />
				<p>نفدت جميع التذاكر</p>
			</div>
		);
	}
	if (!termsRead) {
		return (
			<div className="termContainer">
				<img src="https://maeda.com.sa/khaleej/win/khaleej_logo.png" className="logo" />
				<p>{terms}</p>
				<div className="accept" onClick={() => setTermsRead(true)}>
					موافق
				</div>
			</div>
		);
	}
	if (!sent && family !== null) {
		return (
			<div className="App">
				<div className="backBtn" onClick={() => [isFamily(null), setHasKid(false)]}>
					{'←'}
				</div>
				<div className="container">
					<img src="https://maeda.com.sa/khaleej/win/khaleej_logo.png" className="logo" />
					<label>الاسم</label>
					<input type={'text'} placeholder={'الاسم الثلاثي'} onChange={(e) => setName(e.target.value)} required />
					<label>الجوال</label>
					<input
						type={'tel'}
						placeholder={'05xxxxxxxx'}
						onChange={(e) => setPhone(e.target.value)}
						maxLength={10}
						required
					/>
					{family === 'yes' && (
						<div className="hasKidHolder">
							<label>هل يوجد مرافق ؟</label>
							<div className="answer">
								<div
									className="yes"
									style={{ border: `3px solid ${hasKid ? 'green' : '#fff'}` }}
									onClick={() => setHasKid(true)}
								>
									نعم
								</div>
								<div
									className="no"
									style={{ border: `3px solid ${!hasKid ? 'green' : '#fff'}` }}
									onClick={() => setHasKid(false)}
								>
									لا
								</div>
							</div>
						</div>
					)}
					<label>رقم بطاقة الاحوال</label>
					<input
						type={'tel'}
						placeholder={'سيتم مطابقته عند استلام التذكرة'}
						onChange={(e) => setIdNumber(e.target.value)}
						maxLength={10}
						required
					/>
					{hasKid && (
						<div>
							<label>رقم بطاقة احوال المرافق</label>
							<input
								type={'tel'}
								placeholder={'سيتم مطابقته عند استلام التذكرة'}
								onChange={(e) => setSecondIdNumber(e.target.value)}
								maxLength={10}
								required
							/>
						</div>
					)}
					<label>يرجى اختيار وقت استلام التذكرة</label>
					<div className="periodsHolder">{period_list()}</div>
					{periods.length > 0 &&
						!submitted && (
							<input
								type={'button'}
								value={'حجز موعد استلام التذكرة'}
								onClick={() => [setSubmitted(true), debounce(submit(selectedPeriod), 300)]}
							/>
						)}
					{periods.length > 0 &&
						submitted && <input type={'button'} value={'يرجى الانتظار ...'} style={{ opacity: 0.5 }} />}
				</div>
			</div>
		);
	} else if (family !== null) {
		return (
			<div className="card">
				<h3>تم حجز الموعد بنجاح</h3>
				<div className="book_number">
					<span>رقم الحجز</span>
					<h2>{userId}</h2>
				</div>
				<p className="message">عدم الحضور على الموعد المحدد يلغي الحجز تلقائيًا</p>
				<h2>{textPeriod}</h2>
				<h3>{store.date}</h3>
				<h3>{store.location}</h3>
				<p className="card-name">{store.name}</p>
				<span>{store.phone}</span>
				<div className="screenshot">صوّر الشاشة وأبرز الصورة عند الوصول لاستلام التذكرة</div>
			</div>
		);
	} else {
		return (
			<div className="familyHolder">
				<img src="https://maeda.com.sa/khaleej/win/khaleej_logo.png" className="logo margin-bottom" />
				<div className="familyNo" onClick={() => isFamily('no')}>
					حجز موعد استلام التذاكر
				</div>
			</div>
		);
	}
};

export default App;
