import create from 'zustand';

const useStore = create((set) => ({
	name: null,
	phone: null,
	date: null,
	location: null,
	setName: (e) => set({ name: e }),
	setDate: (e) => set({ date: e }),
	setLocation: (e) => set({ location: e }),
	setPhone: (e) => set({ phone: e })
}));

export default useStore;
